import React from 'react'
import './fmanagement.css'
import inno from '../../assets/cleaning.png'
import cust from '../../assets/Infection0.jpg'
import trust from '../../assets/Surface5.jpg'
import office from '../../assets/Office1.jpg'
import site from '../../assets/site.jpg'
import event from '../../assets/event.jpg'

const Fmanagement=()=>  {
    return (
      <div className='Ftmanage-margin'>
        <div className='Ftmanage_container'>
          <div className='Ftmanage_container-heading'>
              <h1> FACILITY MANAGEMENT SOLUTIONS:</h1>
              <p> Enhancing Facility Security and Efficiency</p>
              <p className='Ftmanage_contentpara'>SharkSecure is your premier partner for cutting-edge Facility solutions, designed to optimize security and 
enhance operational efficiency across various industries. Our expertise in Facility management and 
security systems ensures that your premises are protected and well-maintained, allowing you to focus on 
your core operations because we believe that a well-maintained Facility is the canvas for your success.</p>  
          </div>
        </div>

        <div className='Ftmanage_Card'>
        <div class="Ftmanage_row1">
        <div className="Ftmanage_column1">
          <div className="Ftmanage_card">
            <img src={inno} alt="sample2"/>
            <div className="Ftmanage_container2">
              <h2 className="Ftmanage_Title">Cleaning and Housekeeping</h2>
              <p className='Ftmanage_content'>A clean environment is not just about aesthetics; it's about health and safety.
               Our professional cleaning and housekeeping services ensure that your
               Facility remains a safe and inviting space, creating a lasting positive impression</p>
            </div>
          </div>
        </div>
      
        <div className="Ftmanage_column1">
          <div className="Ftmanage_card">
            <img src={cust} alt="img1"/>
            <div className="Ftmanage_container2">
              <h2 className="Ftmanage_Title"> Infection Control</h2>
              <p className='Ftmanage_content'>In today’s world, infection control is paramount.
               We implement a comprehensive strategy to minimize infection risks within your Facility. 
               Your well-being is our priority, and we leave no stone unturned in safeguarding your health.</p>
            </div>
          </div>
        </div>
      </div>
      </div>


        <div className='Ftmanage_Card'>
        <div class="Ftmanage_row2">
        <div className="Ftmanage_column1">
          <div className="Ftmanage_card">
            <img src={trust} alt="Ftmanage_sample2"/>
            <div className="Ftmanage_container2">
              <h2 className="Ftmanage_Title">Surface Disinfection Program</h2>
              <p className='Ftmanage_content'> Hygiene is non-negotiable.
               Our surface disinfection program is your guarantee of a germ-free environment.
               We use industry-standard techniques and products to keep your Facility free from contaminants,
                ensuring the utmost safety.</p>
            </div>
          </div>
        </div>
      </div>
      </div>


      <div className='Ftmanage_container'>
          <div className='Ftmanage_container-heading'>
              <h1>SOFT SOLUTIONS</h1>
              <p className='Ftmanage_contentpara'>In the realm of soft solutions, our commitment to your ease and comfort is unwavering. 
                Our support services take the weight of administration off your shoulders, freeing you to 
                focus on your core business</p> 
          </div>
        </div>

        <div className='Ftmanage_Card'>
        <div class="Ftmanage_row1">
        <div className="Ftmanage_column1">
          <div className="Ftmanage_card">
            <img src={office} alt="sample2"/>
            <div className="Ftmanage_container2">
              <h2 className="Ftmanage_Title">Office Support</h2>
              <p className='Ftmanage_content'>We understand that running a Facility involves more than just technical systems.
               Our office support services take care of your administrative needs, 
               leaving you with more time to focus on your core business. Consider us your dependable support team.</p>
            </div>
          </div>
        </div>
      
        <div className="Ftmanage_column1">
          <div className="Ftmanage_card">
            <img src={site} alt="img1"/>
            <div className="Ftmanage_container2">
              <h2 className="Ftmanage_Title">Site Management System:</h2>
              <p className='Ftmanage_content'> Efficiency is at the heart of our site management system. 
    We streamline Facility management tasks, making your operations more efficient and cost-effective. 
    Your Facility will run like a well-oiled machine.</p>
            </div>
          </div>
        </div>
      </div>
      </div>


        <div className='Ftmanage_Card'>
        <div class="Ftmanage_row2">
        <div className="Ftmanage_column1">
          <div className="Ftmanage_card">
            <img src={event} alt="Ftmanage_sample2"/>
            <div className="Ftmanage_container2">
              <h2 className="Ftmanage_Title">Event Management (Security)</h2>
              <p className='Ftmanage_content'>Hosting an event? You can relax, knowing that 
    security is our expertise. We specialize in event security management,
     creating a secure environment for your gatherings. Enjoy your event with the 
     confidence that we’ve got your safety covered</p>
            </div>
          </div>
        </div>
      </div>
      </div>


      <div className='Ftmanage_Block-container'>
          <div className='Ftmanage_Block-container-heading'>
              <p className='Ftmanage_Block-content'>We are not just a security agency; we are your partners in safety, artfully crafting secure environments 
that empower you to thrive and excel. Your trust is our inspiration, and we promise to deliver not just 
security but true peace of mind </p>      
              <p className='Ftmanage_Block-content'>Maintenance and Support: Our commitment to excellence doesn’t end with installation. 
SharkSecure offers ongoing maintenance and support services to keep your systems running at 
peak performance. Our team is available 24/7 to address any issues or concerns.</p>
          </div>
  </div>

</div>
    )
  }


export default Fmanagement
