import React from 'react';
import './services.css'; // Create a CSS file for styling

const servicesData = [
  {
    title: 'Security Solutions',
    description: 'E-surveillance, System Integartion, Alarm Monitoring, And Response Service, Visitor Management Services',
  },
  {
    title: 'Facility Management Solutions',
    description: 'Need to frame standard operation, Cleaning And Housekeeping, Infection Control, Surface Disinfection Program',
  },
  {
    title: 'Technical Solutions',
    description: 'Skilled and licensed supervisors and technicians, Operation And Maintenance, Fire And Safety System',
  },
  {
    title: 'Soft Solutions',
    description: 'Office Support, Site Management System, Event Management(Security)',
  },
];

const Services = () => {
  return (
    <div className="services-container">
      <div className="services-row">
        {servicesData.slice(0, 3).map((service, index) => (
          <div key={index} className="service">
            <div className="service-content">
              <h3>{service.title}</h3>
              <p>{service.description}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="center-service">
        {servicesData.slice(3).map((service, index) => (
          <div key={index} className="service">
            <div className="service-content">
              <h3>{service.title}</h3>
              <p>{service.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
