import React from 'react';
import './App.css';
import {Home, Nav, Footer, Contact, About, Services, Company, Stsolutions, Fmanagement, Industries} from './component';
import { BrowserRouter as Router, Route, Routes  } from 'react-router-dom';
function App() {
  return (
    <Router>
      <div>
        <Nav/>
      </div>
      <Routes >
        <Route path='/' element={<Home/>}/>
        <Route path='/contact' element={<Contact/>}/>
        <Route path='/about' element={<About/>}/>
        <Route path='/services' element={<Services/>}/>
        <Route path='/company' element={<Company/>}/>
        <Route path='/stsolutions' element={<Stsolutions/>}/>
        <Route path='/fmanagement' element={<Fmanagement/>}/>
        <Route path='/industries' element={<Industries/>}/>

      </Routes>
      <div>
        <Footer/>
      </div>
    </Router>
  );
}

export default App;
