import React from 'react'
import './block.css'

const Block =({title, subtitle, content})=> {
    return (
      <div className='Block-container'>
        <div className='Block-heading'>
            <h1>{title}</h1>
            <p className='Block-subheading'>{subtitle}</p>
            <p className='Block-content'>{content}</p>
        </div>
        
      </div>
    )
  }


export default Block
