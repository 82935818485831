import React, { useRef} from 'react';
import './contact.css';
import emailjs from "@emailjs/browser";

const Contact = () => {
  const form = useRef();

  const resetForm = () => {
    form.current.reset();
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_c5hgkgj",
        "template_b0dxfwe",
        form.current,
        "PqSCmRt7q69vf5XU7"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Message sent");
          resetForm();
        },
        (error) => {
          console.log(error.text);
          alert("Error sending message");
        }
      );
  };

  return (
    <div className='contact-margin'>
      <div className='contact-loc'>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3598.6909784244667!2d85.11664147539449!3d25.581945877465156!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjXCsDM0JzU1LjAiTiA4NcKwMDcnMDkuMiJF!5e0!3m2!1sen!2sin!4v1698384520693!5m2!1sen!2sin"
          title='google location'
          width="100%"
          height="450"
          style={{ border: 0 }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>

      <div className='contact-heading'>
        <h1>Contact Us</h1>
      </div>
      <div className="contact_split">
        <div className='contact_split-screen'>
          <div className="contact_split-left">
          <h1>Address</h1>
          <p>185,BUDDHIJIVI COLONY, MITHAPUR <br /> Anisabad Phulwari Patna <br /> Bihar - 800002 <br /> India</p>
          <p>Email: admin@sharksecureservices.com</p>
          <p>Phone: 8936017456, 9289567800</p>
          </div>
          <div className="contact_split-right">
            <div className="contact-us">
              <form ref={form} onSubmit={sendEmail}>
                <div className="form-group">
                  <label htmlFor="name">Name:</label>
                  <input
                    type="text"
                    id="name"
                    name="user_name"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="phone">Phone Number:</label>
                  <input
                    type="tel"
                    id="phone"
                    name="user_phone"
                    pattern="[0-9]{10}"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email:</label>
                  <input
                    type="email"
                    id="email"
                    name="user_email"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="message">Message:</label>
                  <textarea
                    id="message"
                    name="message"
                    required
                  ></textarea>
                </div>
                <input type="submit" value="Send" />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
