import React from 'react'
import './card.css';
import guard from '../../assets/guard.jpg'
import img from '../../assets/img1.jpg'
import dog from '../../assets/dog.jpg'
import fire from '../../assets/fire.jpg'


const Card=()=> {
    return (
        <div className='Card'>
        <div class="row1">
        <div className="column1">
          <div className="card">
            <img src={guard} alt="sample2"/>
            <div className="container2">
              <h2 className="Title">Industrial Security</h2>
            </div>
          </div>
        </div>
      
        <div className="column1">
          <div className="card">
            <img src={img} alt="img1"/>
            <div className="container2">
              <h2 className="Title">Executive Protection/Body Guard</h2>
            </div>
          </div>
        </div>

        <div className="column1">
          <div className="card">
            <img src={dog} alt="sample2"/>
            <div className="container2">
              <h2 className="Title">Dog Squad Service</h2>
            </div>
          </div>
        </div>

        <div className="column1">
          <div className="card">
            <img src={fire} alt="sample2"/>
            <div className="container2">
              <h2 className="Title">Safety and Fire Fighting</h2>
            </div>
          </div>
        </div>
      </div>
      </div>
    )
  }


export default Card;

