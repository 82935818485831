import React from 'react';
import './horicard.css';
import download from '../../assets/download.jpg'

const Horicard = () => {
  return (
    <div className="split-screen">
      <div className="left-section">
        <img src={download} alt="Left" />
      </div>
      <div className="center-section">
        <h2>Career</h2>
        <p>At SharkSecure, we believe that our team members are our greatest asset. We offer a rewarding and 
challenging career environment where individuals can grow and excel. Our Human Resource Training and 
Development programs ensure that our team is equipped with the skills and knowledge necessary to
meet the evolving challenges of the security industry. If you are looking for a career that combines your 
passion for security with opportunities for personal and professional growth, we invite you to explore 
the possibilities at SharkSecure. Your journey toward excellence starts here.</p>
      </div>
      <div className="right-section">
        <img src={download} alt="Right" />
      </div>
    </div>
  );
};

export default Horicard;
