import React from "react";
import './about.css';
import image from '../../assets/img4n.png'
import ceo from '../../assets/ceo.jpeg'

const About = () =>{
    return(
        <div className="about-margin">
        <div className="header">
          {/* Image with text overlay */}
          <div className="overlay">
            <h1>Welcome to Our Team</h1>
            <p>Learn more about us and our mission.</p>
          </div>
          <img src={image} alt="About Us" />
        </div>
  
        <section className="admin-section">
          <h2>Board Of Director</h2>
        <div className='about-Card'>
          <div className="about-card">
            <img src={ceo} alt="sample2"/>
            <div className="about-container2">
              <h2 className="about-Title">Jay Shankar Kumar</h2>
              <p className="about-subTitle">Director</p>
            </div>
          </div>
        </div>
  
        </section>

      </div>
        
    )
}

export default About;