import React from 'react'; 
import 'bootstrap/dist/css/bootstrap.css'; 
import Carousel from 'react-bootstrap/Carousel'; 
import './home.css';
import {Card, Multicarousel, Block} from '../../component';
import pic from '../../assets/pic.jpg';
import img1 from '../../assets/pic3n.jpg';
import img2 from '../../assets/pic2.jpg';
import img4 from '../../assets/img4.jpg';

  
export default function Home() { 
  return ( 
    <div className='home-margin'> 
      <Carousel> 
        <Carousel.Item interval={1500}> 
          <img 
            className="d-block_w-100"
            src={pic}
            alt="company image1"
          /> 
          <Carousel.Caption> 
            <h3 className='carouselcaption'>Welcome to SharkSecure - A Trusted Security Partner</h3> 
          </Carousel.Caption> 
        </Carousel.Item> 
        <Carousel.Item interval={1500}> 
          <img 
            className="d-block_w-100"
            src={img1}
            alt="company image2"
          /> 
          <Carousel.Caption> 
            <h3 className='carouselcaption'>Welcome to SharkSecure - A Trusted Security Partner</h3> 
          </Carousel.Caption> 
        </Carousel.Item>
        <Carousel.Item interval={1500}> 
          <img 
            className="d-block_w-100"
            src={img2}
            alt="company image3"
          /> 
          <Carousel.Caption> 
            <h3 className='carouselcaption'>Welcome to SharkSecure - A Trusted Security Partner</h3> 
          </Carousel.Caption> 
        </Carousel.Item>
      </Carousel> 
    
        <div className="app__header_section__padding">
        <h1>
        At SharkSecure, we take pride in safeguarding what matters most to you. As a leading security agency, 
we are committed to providing top-notch security solutions tailored to your unique needs. With a team 
of dedicated professionals, cutting-edge technology, and a passion for excellence, we've earned our 
reputation as a reliable security partner.           </h1>
        <div className="app__header-content">
            <p>Our scope of operations is exclusively within the borders of India, and it is our unwavering commitment 
to ensure that the services we provide are of the highest standard in terms of efficiency and quality. 
With a deep understanding of the unique security challenges and requirements present within the 
Indian context, we have honed our expertise to deliver services that not only meet but exceed the 
expectations of our clients. Our team’s dedication to excellence, combined with a comprehensive 
knowledge of the local landscape, allows us to provide you with security solutions that are not just good 
but exceptional, ensuring your peace of mind and the safety of what matters most within the Indian 
subcontinent.</p>
            <div className="app__header-content__input">
                <a href="/industries"><button type="button">Want To Know More</button></a>
            </div>
        </div>
        <div className="app__header-image">
                <img src={img4} alt="working"/>
        </div>
        </div>
         <div>
         <div className='Home_Card-heading'>
        <h1>What We Offer</h1>
          <p>Sharksecure Services Pvt. Ltd is India/Bihar based and confine its operation all over India.</p>
        </div>
           <Card/>
         </div>

          <div className='Home_block-margin'>
            <Block title=" Investing In Excellence:" subtitle="Human Resource Training And Development" content="At SharkSecure, we recognize that our most valuable asset is our team. Our commitment to excellence 
extends to every member of our staff, and we believe in nurturing and enhancing their skills and 
knowledge through comprehensive Human Resource Training and Development programs."/>
          </div>

        <div className='Home_Card-heading'>
        <h1>Industries We Serve</h1>
        </div>
          <div>
            <Multicarousel/>
          </div>

          

          <div className='home_split'>
              <div className="split-screen">
                <div className="split-left">
                  <h1>Our Approach</h1>
                  <p>Investing in Human Resource Training and Development is not just about improving the skills of our team 
but also enhancing the services we provide to our clients. Well-trained and motivated staff members are 
more dedicated, efficient, and capable of addressing the unique security challenges our clients face.
At SharkSecure, we believe that our investment in the development of our team is an investment in the 
security and satisfaction of our clients. By prioritizing Human Resource Training and Development, we 
ensure that our team remains at the forefront of the security industry, delivering excellence in every 
aspect of our services.</p>
                </div>
                <div className="split-right">
                  <h1>Why It Matters</h1>
                  <p>We understand that a well-trained and motivated team is crucial to the success of our security agency. 
Our Human Resource Training and Development programs are designed to equip our personnel with the 
skills, knowledge, and expertise necessary to meet the evolving challenges of the security industry. We 
prioritize continuous learning, professional growth, and personal development, ensuring that our team is 
not only well-prepared but also inspired to excel.</p>
                </div>
              </div>
          </div>


          
        </div>

     
  ); 
}


/*<h2 className="home_heading"></h2>
              <p className="home_content"></p>*/


/* <h2 className="home_heading"></h2>
              <p className="home_content"></p>*/