import React from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './multicarousel.css'
import img1 from '../../assets/hospital.png'
import img2 from '../../assets/school.png'
import img3 from '../../assets/manufacturing.png'
import img4 from '../../assets/residential com.png'
import img5 from '../../assets/Retail Business.png'

const Multicarousel=()=>{

return(

<Carousel
  additionalTransfrom={0}
  arrows
  autoPlay={true}
  autoPlaySpeed={2000}
  centerMode={false}
  className=''
  containerClass="container-with-dots"
  dotListClass=""
  focusOnSelect={false}
  infinite
  keyBoardControl
  minimumTouchDrag={80}
  pauseOnHover
  renderArrowsWhenDisabled={false}
  renderButtonGroupOutside={false}
  renderDotsOutside={false}
  responsive={{
    desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    paritialVisibilityGutter:40 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    partialVisibilityGutter:30// optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    paritialVisibilityGutter:30// optional, default to 1.
  }
  }}
  rewind={false}
  rewindWithAnimation={false}
  rtl={false}
  shouldResetAutoplay
  showDots={false}
  sliderClass=''
  slidesToSlide={1}
  swipeable
  >
  <div className='multi-carousel'><a href='/industries'><img src={img1} alt="hospital iamge"/></a>
    <div className="multi-container">
        <h2 className="Title1">Healthcare</h2>
        <p className="subTitle1"><a href='/industries'>Read more...</a></p>
    </div>
  </div>
  <div className='multi-carousel'><a href='/industries'><img src={img2} alt='education institute'/></a>
  <div className="multi-container">
        <h2 className="Title1">Education</h2>
        <p className="subTitle1"><a href='/industries'>Read more...</a></p>
    </div>
  </div>
  <div className='multi-carousel'><a href='/industries'><img src={img3} alt='manufacturing plant'/></a>
  <div className="multi-container">
        <h2 className="Title1">Manufacturing</h2>
        <p className="subTitle1"><a href='/industries'>Read more...</a></p>
    </div>
  </div>
  <div className='multi-carousel'><a href='/industries'><img src={img4} alt='residential complex'/></a>
  <div className="multi-container">
        <h2 className="Title1">Residential Complex</h2>
        <p className="subTitle1"><a href='/industries'>Read more...</a></p>
    </div>
  </div>
  <div className='multi-carousel'><a href='/industries'><img src={img5} alt='retail business'/></a>
  <div className="multi-container">
        <h2 className="Title1">Retail Business</h2>
        <p className="subTitle1"><a href='/industries'>Read more...</a></p>
    </div>
  </div>
  </Carousel>
  )}

  export default Multicarousel;