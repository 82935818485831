import React, {useState} from "react";
import './nav.css';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import logo from '../../assets/logo.png'


const Menu =()=>(
    <>
    <div class="navbar">
  <a href="/">Home</a>
  <div class="subnav">
    <button class="subnavbtn">Our Company <i class="fa fa-caret-down"></i></button>
    <div class="subnav-content1">
      <a href="/company">About SharKSecure Private Limited</a>
      <a href="/about">The Board Of Directors</a>
    </div>
  </div>
  <div class="subnav">
    <button class="subnavbtn">Solutions <i class="fa fa-caret-down"></i></button>
    <div class="subnav-content2">
      <a href="/stsolutions">Security And Technical Solutions</a>
      <a href="/fmanagement">Facility Management And Soft Solutions</a>
    </div>
  </div>
  <a href="/industries">Industries</a>
  <a href="/contact">Contact Us</a>
</div>
    </>
)


const Navbar = () => {
    const [toggleMenu, settoggleMenu]= useState(false);
    return(
    <div className="app_navbar">
        <div className="app__navbar-links">
            <div className="app__navbar-links_logo">
                <img src={logo} alt="logo"/>
                <p>Sharksecure Services <br/> Private Limited</p>
            </div>
            <div className="app__navbar-links_container">
                <Menu/>
            </div>
        </div>
        
        <div className="app__navbar-menu">
            {toggleMenu
            ? <RiCloseLine color='#000' size={27} onClick={()=>settoggleMenu(false)}/>
            :  <RiMenu3Line color='#000' size={27} onClick={()=>settoggleMenu(true)}/>
            }
            {toggleMenu &&(
                <div className="app__navbar-menu_container">
                    <div className="app__navbar-menu_container-links">
                        <Menu/>
                    </div>
                </div>
            )}
        </div>
    </div>
    )
}

export default Navbar;



