import React from "react";
import './footer.css';

const Footer = () =>{
    return(
<div className="footer">
  <div className="container">
    <div className="row">
      <div className="footer-col">
        <h4>Company</h4>
        <ul>
          <li><a href="/company">about us</a></li>
          <li><a href="/about">board of directors</a></li>
          <li><a href="/stsolutions">security and technical solutions</a></li>
          <li><a href="/fmanagement">facility management and soft solutions</a></li>
        </ul>
      </div>
      <div className="footer-col">
        <h4>get help</h4>
        <ul>
          <li><a href="/contact">contact us</a></li>
        </ul>
      </div>
      <div className="footer-col">
        <h4>Contact Details</h4>
        <ul>
          <li><a href="/">SharkSecure Services Private Limited</a></li><br/>
          <li><p>Address: 185,BUDDHIJIVI COLONY, MITHAPUR <br /> Anisabad Phulwari Patna <br /> Bihar - 800002 <br /> India</p></li>
          <li><p>Phone: 8936017456, 9289567800</p></li>
          <li><p>Email: admin@sharksecureservices.com</p></li>
        </ul>
      </div>
      <div className="footer-col">
        <h4>follow us</h4>
        <div className="social-links">
          <a href="#fa"><i className="fab fa-facebook-f"></i></a>
          <a href="#tw"><i className="fab fa-twitter"></i></a>
          <a href="https://instagram.com/sharksecureservices?igshid=MTNiYzNiMzkwZA=="><i className="fab fa-instagram"></i></a>
          <a href="https://wa.link/8w7zmx"><i className="fab fa-whatsapp"></i></a>
        </div>
      </div>
    </div>
  </div>
  </div> 
    )
}

export default Footer;