import React from 'react'
import './stsolutions.css'
import '../fmanagement/fmanagement.css'
import Block from '../block/Block'
import inno from '../../assets/e-sur.jpg'
import cust from '../../assets/sys.jpg'
import comm from '../../assets/visitor.png'
import trust from '../../assets/alarm.jpg'
import skilled from '../../assets/skilled.png'
import operation from '../../assets/operation.jpg'
import firesaf from '../../assets/firesafety.jpg'

const Stsolutions=()=> {

    return (
      <div className='Stsol-margin'>
        <div className='Stsol_container'>
          <div className='Stsol_container-heading'>
              <h1> SECURITY SOLUTIONS</h1>
              <p>At SharkSecure, we understand that security needs are as diverse as the clients we serve. That’s why 
we offer a range of comprehensive security solutions designed to meet your unique requirements. 
Whether you’re looking for personal protection, safeguarding your assets, or ensuring corporate 
security, we’ve got you covered.
</p>
<p className='Stsol_contentpara'>When it comes to security, our commitment goes beyond protection; it’s about your peace of mind. Our 
cutting-edge e-surveillance keeps a watchful eye on your surroundings, 24/7, ensuring potential threats 
are spotted and deterred. Seamless system integration creates a security network that works in 
harmony, </p>
          </div>
        </div>

        <div className='Stsol_Card'>
        <div class="Stsol_row1">
        <div className="Stsol_column1">
          <div className="Stsol_card">
            <img src={inno} alt="sample2"/>
            <div className="Stsol_container2">
              <h2 className="Stsol_Title">E-Surveillance</h2>
              <p className='Stsol_content'> Your safety is our top priority. Our e-surveillance services use cutting-edge 
technology to watch over your premises, providing a 24/7 constant and vigilant presence. Rest
assured that potential threats are detected and deterred, and real-time alerts ensure a rapid 
response to any security concerns.</p>
            </div>
          </div>
        </div>
      
        <div className="Stsol_column1">
          <div className="Stsol_card">
            <img src={cust} alt="img1"/>
            <div className="Stsol_container2">
              <h2 className="Stsol_Title"> System Integration</h2>
              <p className='Stsol_content'> Seamlessly connecting your security systems is the foundation of a wellprotected environment. Our system integration expertise ensures that every component of your 
security network works together harmoniously, creating a unified shield that strengthens your 
protection.</p>
            </div>
          </div>
        </div>
      </div>
      </div>


        <div className='Stsol_Card'>
        <div class="Stsol_row1">
        <div className="Stsol_column1">
          <div className="Stsol_card">
            <img src={trust} alt="Stsol_sample2"/>
            <div className="Stsol_container2">
              <h2 className="Stsol_Title">Alarm Monitoring and Response Service</h2>
              <p className='Stsol_content'>When alarms sound, our team is ready to respond 
promptly. We’ve got your back around the clock, ready to take swift action in the face of any 
potential security breach, giving you the peace of mind you deserve.</p>
            </div>
          </div>
        </div>

        <div className="Stsol_column1">
          <div className="Stsol_card">
            <img src={comm} alt="sample2"/>
            <div className="Stsol_container2">
              <h2 className="Stsol_Title"> Visitor Management Services</h2>
              <p className='Stsol_content'>Your facility’s security starts at the front door. Our visitor 
management services put you in control of who enters your premises. We provide a warm 
welcome to authorized visitors while keeping potential threats at bay.

</p>
            </div>
          </div>
        </div>
      </div>
      </div>


      <div className='Ftmanage_container'>
          <div className='Ftmanage_container-heading'>
              <h1> TECHNICAL SOLUTIONS:</h1>
              <p className='Ftmanage_content'>Our team of skilled and licensed supervisors and technicians ensure the seamless operation of your 
Facility. With a focus on excellence, our operation and maintenance services maintain the functionality 
and reliability of your technical systems. </p>      
          </div>
      </div>

      <div className='Ftmanage_Card'>
        <div class="Ftmanage_row1">
        <div className="Ftmanage_column1">
          <div className="Ftmanage_card">
            <img src={skilled} alt="sample2"/>
            <div className="Ftmanage_container2">
              <h2 className="Ftmanage_Title">Skilled and Licensed Supervisors and Technicians</h2>
              <p className='Ftmanage_content'>Our team of skilled and licensed supervisors and technicians takes care of your
     technical needs with dedication and precision. 
     We ensure that your Facility operates seamlessly and efficiently, allowing you to focus on your core activities.</p>
            </div>
          </div>
        </div>
      
        <div className="Ftmanage_column1">
          <div className="Ftmanage_card">
            <img src={operation} alt="img1"/>
            <div className="Ftmanage_container2">
              <h2 className="Ftmanage_Title">Operation and Maintenance</h2>
              <p className='Ftmanage_content'>Let us handle the nuts and bolts. Our operation and maintenance 
    services guarantee the continuous functionality and reliability of your Facility’s 
    technical systems. You can count on us to keep things running smoothly.</p>
            </div>
          </div>
        </div>
      </div>
      </div>


        <div className='Ftmanage_Card'>
        <div class="Ftmanage_row2">
        <div className="Ftmanage_column1">
          <div className="Ftmanage_card">
            <img src={firesaf} alt="Ftmanage_sample2"/>
            <div className="Ftmanage_container2">
              <h2 className="Ftmanage_Title">Fire and Safety System:</h2>
              <p className='Ftmanage_content'>Fire and safety are non-negotiable aspects of Facility management. 
    Our technical experts are well-versed in these systems and will ensure that your Facility is 
    fully protected and in compliance with safety regulations.</p>
            </div>
          </div>
        </div>
      </div>
      </div>

      <div className='Stsol_block-margin'>
          <Block title="Solutions" content="SharkSecure offers a broad spectrum of solutions to meet your diverse security needs. From state-of-theart e-surveillance and system integration to responsive alarm monitoring and visitor management 
services, we provide a comprehensive suite of security offerings. Our facility management solutions 
encompass cleaning, housekeeping, infection control, and surface disinfection, ensuring that your 
environment remains safe and healthy. In the realm of technical solutions, we provide skilled supervisors 
and technicians to handle operation and maintenance, as well as fire and safety systems. Additionally, 
our soft solutions include office support, site management systems, and expert event management 
(security) services."/>
      </div>

      </div>
    )
  }


export default Stsolutions
