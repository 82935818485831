import React from 'react';
import './industries.css';
import img1 from '../../assets/hospital.png'
import img2 from '../../assets/school.png'
import img3 from '../../assets/manufacturing.png'
import img4 from '../../assets/residential com.png'
import img5 from '../../assets/Retail Business.png'
import inno from '../../assets/inno.jpg';
import cust from '../../assets/cust.png';
import comm from '../../assets/comm.jpg';
import trust from '../../assets/trust.jpg';

const LineBlock = ({ title, pic, content }) => (
  <div className='industries_container-left'>
    <div className='industries_heading-left'>
      <h1>{title}</h1>
      <img src={pic} alt="img1" />
      <p>{content}</p>
    </div>
  </div>
);

const Industries = () => {
  return (
    <div className='industries-margin'>
    <div className='industries'>
      <div className='industries_heading'>

        <h1>Industries We Serve</h1>
      </div>

      <LineBlock
        key="health"
        title="Health"
        pic={img1}
        content="In the realm of healthcare, where every moment is critical, we ensure that your facility is a sanctuary of safety and well-being. Our commitment to this noble industry is unwavering. We provide security solutions that resonate with the delicate balance between health and security. The precision of our services ensures that medical professionals can focus solely on patient care, unburdened by security concerns. With meticulous infection control and vigilant surveillance, we create an environment where healing and recovery can occur without disturbance. In the domain of healthcare, we are your devoted guardians, ensuring that the well-being of both patients and staff remains the top priority."
      />

      <LineBlock
        key="education"
        title="Education"
        pic={img2}
        content="In the realm of education, where minds are nurtured and futures are shaped, our services are designed to create a haven for learning and growth. Our comprehensive security and facility management solutions not only guarantee safety but also create an environment where knowledge and innovation thrive. In the educational sector, we are more than protectors; we are enablers of a brighter future."
      />

      <LineBlock
        key="manufacturing"
        title="Manufacturing"
        pic={img3}
        content="In the world of manufacturing, where efficiency and safety are the pillars of success, we play a pivotal role. Our technical solutions are not just about keeping your systems running smoothly; they are about boosting productivity and securing the well-being of your workforce. We are dedicated to optimizing your facility’s technical systems, ensuring they function seamlessly and that your workforce is safeguarded. In the manufacturing industry, we are your partners in achieving excellence and growth."
      />

      <LineBlock
        key="residential"
        title="Residential Complex"
        pic={img4}
        content="Your residential complex is not just a building; it’s a haven, and we are its custodians. Our residential security solutions create an environment where you and your loved ones can enjoy life without the shadow of insecurity. We provide state-of-the-art security systems, efficient surveillance, and responsive services, all customized to the unique security needs of your residential complex. Your safety, comfort, and peace of mind are the cornerstones of our commitment."
      />

      <LineBlock
        key="retail"
        title="Retail Business"
        pic={img5}
        content="In the world of retail, where every interaction matters, we understand the significance of creating a secure and customer-friendly environment. We provide state-of-the-art surveillance, responsive alarm systems, and visitor management services that not only protect your business and assets but also ensure that customers can shop with confidence. We will partner with you in creating an environment where shopping is not just a transaction but an enjoyable and secure experience."
      />
    </div>
    <div className='industries_Bigcontainer'>
    <div className='industries_heading2'><h1>Why Choose Us</h1></div>
    <div className='industries_Card'>
        <div className="industries_column1">
          <div className="industries_card">
            <img src={inno} alt="sample2"/>
            <div className="industries_container2">
              <p className='industries_content'>  We value long standing relationships with our partners and customers through the trust we 
built using our quality solutions. Building a strong reputation for trustworthiness and reliability is our 
approach and as our client you can count on us 24/7, 365 days a year</p>
            </div>
          </div>
        </div>
      
        <div className="industries_column1">
          <div className="industries_card">
            <img src={trust} alt="img1"/>
            <div className="industries_container2">
              <p className='industries_content'> Conducive & Supporting working environment through efficient human resource 
management handled by the efficient and skill management team. </p>
            </div>
          </div>
        </div>
      </div>


      <div className='industries_Card'>
        <div class="industries_row1">
        <div className="industries_column1">
          <div className="industries_card">
            <img src={cust} alt="sample2"/>
            <div className="industries_container2">
              <p className='industries_content'>  Skilled & qualified staffs: Our team comprises highly trained security experts with years of 
experience in the field. All employees trained and equipped with required set of skills and training 
with our knowledge and training partners.</p>
            </div>
          </div>
        </div>
      
        <div className="industries_column1">
          <div className="industries_card">
            <img src={comm} alt="img1"/>
            <div className="industries_container2">
              <p className='industries_content'> We believe in giving back to the community. SharkSecure actively participates in community 
safety programs and initiatives to make the world a safer place.</p>
            </div>
          </div>
        </div>
      </div>
      </div>
      </div>

    </div>
  );
};

export default Industries;
