import React from 'react';
import './company.css';
import Block from '../block/Block';
import Horicard from '../horicard/Horicard';
import inno from '../../assets/inno.jpg';
import cust from '../../assets/cust.png';
import comm from '../../assets/comm.jpg';
import trust from '../../assets/trust.jpg';

const CompanyBlock = ({ title, content, title1, content1, img, img1 }) => (
  <>
    <div className='company_block-container'>
      <div className='company_block-heading'>
        <img src={img} alt="img1" />
        <h1>{title}</h1>
        <p>{content}</p>
      </div>
    </div>

    <div className='company_block-container-left'>
      <div className='company_block-heading-left'>
        <img src={img1} alt="img1" />
        <h1>{title1}</h1>
        <p>{content1}</p>
      </div>
    </div>
  </>
);

const Company = () => {
  return (
    <div className='company'>
      <div className='company_container'>
        <div className='company_container-heading'>
          <h1>Our Mission</h1>
          <p>
            At SharkSecure, our mission transcends the ordinary; it's a solemn
            commitment to the security, wellbeing, and trust of our valued
            clients. We exist to safeguard what matters most to you, to stand as
            unwavering guardians of your peace of mind. Our mission extends far
            beyond mere security solutions; it embodies an uncompromising
            dedication to excellence. We pledge to provide services that not only
            meet but exceed your highest expectations, setting new benchmarks in
            the industry.
          </p>
        </div>
      </div>

      <CompanyBlock
        img={inno}
        title="Innovation"
        content="We drive its evolution. Our promise is to remain at the forefront of industry advancements, continually exploring innovative methods to enhance your protection. We are problem solvers, innovators, and visionaries, committed to making your security experience safer, smarter, and more effective."
        img1={cust}
        title1="Customization"
        content1="We recognize the unique nature of each client's security needs, whether in healthcare, education, manufacturing, residential living, or retail. Our mission is to provide tailored solutions that adapt to your distinct requirements, ensuring that your security is our priority."
      />

      <CompanyBlock
        img={trust}
        title="Trust"
        content="We hold your trust in the highest regard. Our commitment is to maintain and strengthen that trust by delivering not just security but the assurance of reliability, transparency, and unwavering support."
        img1={comm}
        title1="Community Safety"
        content1="It's an integral part of our mission. We actively engage in initiatives that extend our commitment to the community we serve. Our mission is to be a positive force for change, working tirelessly to make neighborhoods safer, fostering a secure world one community at a time."
      />

      <div className='company_container1'>
        <div className='company_container-heading1'>
          <p>
            Ultimately, our mission revolves around your peace of mind. When you choose SharkSecure, you choose a promise of safety, a commitment to excellence, and a dedication to safeguarding what matters most. Your peace of mind is not just our goal; it's our mission, our purpose, and the guiding light that inspires everything we do.
          </p>
        </div>
      </div>

      <div className='company_horicard-margin'>
        <Horicard />
      </div>

      <div className='company_block-margin'>
        <Block
          title="Team"
          content="Our team is not just a group of professionals; we are a family dedicated to your safety. We are security specialists, technology innovators, and dedicated professionals who are committed to ensuring your well-being. We understand that every client is unique, and we tailor our expertise to match your specific needs. Our team members undergo continuous training and development to stay sharp and current with the ever-evolving security landscape. At SharkSecure, your security is not just our duty; it’s our passion."
        />
      </div>

      
    </div>
  );
};

export default Company;
